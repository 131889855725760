import React, { useEffect } from 'react';
import { gsap } from "gsap"

const Preloader = ({className=''}) => {
  useEffect(() => {
    document.querySelector('.preloader .inner').classList.add('active');
    gsap.to(".preloader--logo", { opacity: 0, duration: 0.75, delay: 2.5 })
    gsap.to(".preloader", { height:'0px', ease:'Power2.easeInOut', duration: 0.75, delay:3 })
    gsap.fromTo(".hero--title .first", {opacity:0, y:10}, { opacity:1, y:0, ease:'Power3.easeOut', duration:1, delay:3.5 })
    gsap.fromTo(".hero--title .second", {opacity:0, y:10}, { opacity:1, y:0, ease:'Power3.easeOut', duration:1, delay:3.65 })
  });
  return (
  <div className='preloader flex'>
    <div className='ma inner'>
      <div className='logo--main alt preloader--logo m-mb80'/>
    </div>
  </div>
  )
}

export default Preloader
