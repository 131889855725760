import * as React from "react" 
import About from "../components/about"
import HeroBanner from "../components/heroBanner"
import CaseStudy from "../components/caseStudy"
import Preloader from "../components/preloader"
import Layout from "../components/layout"
import LineIn from "../components/lineIn"
import Spacer from "../components/spacer"
import Faqs from "../components/faqs"
import Cta from "../components/cta"
import Seo from "../components/seo"
import { graphql } from "gatsby" 


const IndexPage = ({data}) => {
  data = data.property
  return (
  <>
    <Seo title="Home" />
    <Layout>
      <Preloader />
      <HeroBanner title={data.heroTitle} image={data.heroImage}/>
      <About title={data.aboutTitle} content={data.aboutContent} image={data.aboutImage} />
      <Spacer />
      <CaseStudy title={data.caseTitle} content={data.caseIntroduction} image={data.caseImage} breakdown={data.moreBreakdown} moreTitle={data.moreTitle} moreContent={data.moreContent}/>
      <Spacer className='m-hide' />
      <LineIn />
      <Faqs faqs={data.faqs} />
      <Spacer />
      <Cta title='Contact Us' link='/contact' image={data.contactImage}/>
    </Layout>
  </>
  )
}

export default IndexPage

export const query = graphql`
  query Homepage {
    property:datoCmsWaltProperty {
      faqs {
        content
        title
      }
      contactImage {
        gatsbyImageData
      }
      aboutContent
      aboutImage {
        gatsbyImageData
      }
      aboutTitle
      caseImage {
        gatsbyImageData
      }
      caseIntroduction
      caseTitle
      heroImage {
        gatsbyImageData
      }
      heroTitle
      moreBreakdown {
        title
        content
      }
      moreTitle
      moreContent
    }
  }
`