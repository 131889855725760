import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const HeroBanner = ({title, image}) => (
  <div className='hero-banner flex p40 m-p0 m-wrap'>
    <div className='title ma ml0 pos-rel z-2 m-100 m-p20 m-mt80'>
      <h1 className='m0 hero--title' dangerouslySetInnerHTML={{ __html: title }} />
    </div>
    <div className='bg-wrapper flex m-wrap'>
      
      <div className='w-50 p40 flex mb40 m-m0 mla m-100 m-p0'>
        <div className='pos-rel w-100'>
          <div className='bg-image bg-grey br10 m-br0 overflow-hidden'>
            <GatsbyImage className='bg-image' image={image.gatsbyImageData} alt={title} />
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default HeroBanner
