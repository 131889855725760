import * as React from "react"
import scrollTo from "./scrollTo"
import Spacer from "../components/spacer"
import { GatsbyImage } from "gatsby-plugin-image"

const About = ({title, content, image}) => (
  <div id="about" className='about--us bg-green white p40 m-p0'>
    <Spacer className='small' />
    <div className='flex p40 m-wrap m-pb0'>
      <div className='w-50 flex m-100'>
        <div className='mta mba'>
          <h2 className='m0 mb40 m-mb20 beige fade-in' data-sal>{title}</h2>
          <div className='large max-485 pr20 fade-in' data-sal data-sal-delay='400'>{content}</div>
          <p onClick={() => scrollTo('#case')} role='presentation' className='m0 button light medium mt40 m-mb40 m-mt20 fade-in' data-sal data-sal-delay='400'>Learn More</p> 
        </div>
      </div>
      <div className='w-50 m-100'>
        <div className='max-600 w-100 ma'>
          <div className='ratio-1-1 br10 pos-rel overflow-hidden'>
            <GatsbyImage className='bg-image' image={image.gatsbyImageData} alt={title} />
          </div>
        </div>
      </div>
    </div>
    <Spacer className='small' />
  </div> 
)

export default About
