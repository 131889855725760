import * as React from "react"
import Spacer from "../components/spacer"
import { GatsbyImage } from "gatsby-plugin-image"

const CaseStudy = ({title, content, image, moreTitle, moreContent, breakdown}) => (
  <div id="case" className='case--study p40 text-center'>
    <p className='m0 h2 mb40 m-mb20 fade-in' data-sal>Case Study</p>
    <h3 className='h1 m0 mb40 fade-in' data-sal data-sal-delay='400' dangerouslySetInnerHTML={{ __html: title }} />
    <div className='content large max-600 ma mb40 fade-in' data-sal data-sal-delay='600'>
      {content}
    </div>
    <div className='max-1000 ma'>
      <div className='ratio-1-2 br10 overflow-hidden pos-rel'>
        <GatsbyImage className='bg-image' image={image.gatsbyImageData} alt={title} />        
      </div>
    </div>
    <Spacer />
    <div className='flex text-left m-wrap'>
      <div className='w-50 m-100 flex'>
        <div className='ma w-100 mr40'>
          <p className='m0 h2 fade-in' data-sal dangerouslySetInnerHTML={{ __html: moreTitle }} />
          <div className='content max-500 medium mt40 m-mt20 m-mb40 fade-in' data-sal data-sal-delay='400'>
            {moreContent}
          </div>
        </div>
      </div>
      <div className='w-50 m-100'>
        <div className='bg-white p40 bl10 br10 mrn40 max-700 mla'>
          <div className='ml40 m-ml20 mt40 mb40'>
            {breakdown.map((stage, index) => { 
              return (
                <div key={index} className='mb40 fade-in' data-sal data-sal-delay={index*200+200}>
                  <p className='m0 h2 mb20'>{stage.title}</p>
                  <p className='m0 max-450 medium'>{stage.content}</p>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default CaseStudy
