import * as React from "react"

const Faqs = ({faqs}) => {

  const onClick = (index) => {
    var faq = document.querySelector('.faq[data-index="'+index+'"]');
    if(faq.classList.contains('active')) {
      faq.classList.remove('active');
      faq.querySelector('.content').style.height = '0px';
    } else {
      faq.classList.add('active');
      faq.querySelector('.content').style.height = faq.querySelector('.content .inner').offsetHeight+'px';
    }
  }
  return (
    <div className='p40'>
      <div className='text-center'>
        <p className='m0 h1 mb40 mt40 m-mt20 m-mb0 fade-in' data-sal>Faqs</p>
      </div>
      <div className='faqs medium pt40'>
        <div className='max-1000 ma fade-in' data-sal data-sal-delay='400'>
          {faqs.map((faq, index) => { 
            return (
              <div className='faq' data-index={index} key={index} onClick={() => onClick(index)} role='presentation'>
                <div className='title pt20 pb20 pr20 bb1'>
                  {faq.title}
                </div>
                <div className='content'>
                  <div className='inner'>
                    <div className='max-800 pt20 pb20'>
                      {faq.content}
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Faqs
