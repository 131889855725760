import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Cta = ({title, link, image}) => (
  <Link to={link} className='link image-zoom'>
    <div className='cta h-550 p40 bg-grey flex pos-rel overflow-hidden'>
      <p className='ma h1 white pos-rel z-2 fade-in' data-sal>{title}</p>
      <GatsbyImage className='bg-image br-85' image={image.gatsbyImageData} alt={title} />
    </div>
  </Link>
)

export default Cta
